import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

import EmailSignature from "../components/emailSignature";

const IndexPage = ({ data }) => {
  const staffMembers = data.allStaff.edges;

  return (
    <Layout>
      <div className="paper">
        <h3 className="cta">
          <a
            href="https://www.loom.com/share/48290f569c234d778f904c666e7d710c"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>{" "}
          to learn how you can add your signature to your Gmail.
        </h3>
      </div>
      <div className="paper paper-grid">
        {staffMembers.map((staffMember) => (
          <EmailSignature
            key={staffMember.node.id}
            name={staffMember.node.name}
            title={staffMember.node.title}
            email={staffMember.node.email}
            phone={staffMember.node.phone1}
            phone2={staffMember.node.phone2}
            ag={staffMember.node.ag}
          />
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allStaff: allSanityStaffMember(sort: { fields: name }) {
      edges {
        node {
          id
          name
          phone1
          phone2
          slug {
            current
          }
          title
          email
          ag
        }
      }
    }
  }
`;

export default IndexPage;
