import React from "react";

function EmailSignature(props) {
  const icon = props.ag
    ? "https://res.cloudinary.com/dcnlacxzw/image/upload/v1583351894/Attorney%20Group/windows-icon_copy_hrl21t.png"
    : "https://res.cloudinary.com/dcnlacxzw/image/upload/c_crop,h_768,w_768/v1622665581/Johnson%20Firm/CLEAR_PNG_JF_-_Secondary_-_White_r8ed9k.png";

  const brand = props.ag
    ? "https://res.cloudinary.com/dcnlacxzw/image/upload/c_thumb,h_75,q_100,w_250,y_0/v1583353849/Email%20Signature%20Icons/AG_Logo_Secondary_Blue--250x250_ikddn6_txknfv.png"
    : "https://res.cloudinary.com/dcnlacxzw/image/upload/v1623258966/Johnson%20Firm/Asset_5_aixzey.png";

  const brandHeight = props.ag ? 60 : 47;

  const leftMargin = props.ag ? "10px" : "-5px";

  const domain = props.ag ? "attorneygroup.com" : "yourattorney.com";
  const facebook = props.ag ? "AttorneyGroup" : "TheJohnsonFirm";
  const twitter = props.ag ? "attorneygroup" : "JohnsonFirmLR";
  const linkedin = props.ag ? "attorney-group" : "6630095";

  return (
    <table
      className="card"
      style={{
        width: 442,
        padding: 0,
        border: 0,
        borderSpacing: 0,
        margin: 0,
        fontFamily:
          "'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif",
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              padding: "15px 0 0 0",
              verticalAlign: "bottom",
              width: "192px",
            }}
          >
            <div style={{ height: 140 }}>
              <div style={{ marginBottom: props.ag ? 20 : 0 }}>
                <a
                  style={{ display: "block" }}
                  href={`https://${domain}/?utm_medium=email_signature&amp;utm_source=email&amp;utm_content=HL`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ marginBottom: 0, marginLeft: leftMargin }}
                    src={brand}
                    alt="Johnson Firm Logo"
                    width="200"
                    height={brandHeight}
                  />
                </a>
              </div>
              <div
                style={{
                  margin: "5px 18px 10px 0",
                  fontSize: 0,
                  align: "right",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <a href={`https://www.facebook.com/${facebook}`}>
                    <img
                      src="https://attorneygroup.com/images/email/Facebook.jpg"
                      alt="Facebook Icon"
                      width="20"
                      height="20"
                    />
                  </a>
                </span>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <a href={`https://twitter.com/${twitter}`}>
                    <img
                      src="https://attorneygroup.com/images/email/Twitter.jpg"
                      alt="Twitter Icon"
                      width="20"
                      height="20"
                    />
                  </a>
                </span>
                <span
                  style={{
                    display: "inline-block",
                    marginLeft: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <a href={`https://www.linkedin.com/company/${linkedin}/`}>
                    <img
                      src="https://attorneygroup.com/images/email/Linkedin.jpg"
                      alt="linkedin Icon"
                      width="20"
                      height="20"
                    />
                  </a>
                </span>
              </div>
            </div>
          </td>
          <td
            style={{ padding: "10px 10px 10px 10px", verticalAlign: "bottom" }}
            colSpan="2"
            width="250"
          >
            <p
              style={{
                color: "#39455e",
                textAlign: "right",
                fontSize: "24px",
                fontWeight: "bold",
                margin: "0 0 5px 0",
              }}
            >
              {props.name}
            </p>
            <p
              style={{
                color: props.ag ? "#e95355" : "#e95355",
                textAlign: "right",
                fontSize: "18px",
                margin: "0 0 5px 0",
              }}
            >
              {props.title}
            </p>
            <p
              style={{
                color: "#6680ae",
                textAlign: "right",
                fontSize: "16px",
                margin: "5px 0 0 0",
              }}
            >
              {props.phone && (
                <a
                  style={{ color: "#6680ae", textDecoration: "none" }}
                  href={`tel:${props.phone || "501-777-7777"}`}
                >
                  {props.phone}
                </a>
              )}
            </p>
            {props.phone2 && (
              <p
                style={{
                  color: "#6680ae",
                  textAlign: "right",
                  fontSize: "16px",
                  margin: "5px 0 0 0",
                }}
              >
                <a
                  style={{ color: "#6680ae", textDecoration: "none" }}
                  href={`tel:${props.phone2}`}
                >
                  {props.phone2}
                </a>
              </p>
            )}
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "0 24px",
              borderTop: "solid 16px #c0d3e6",
              textAlign: "right",
              color: "#6680ae",
              fontSize: "20px",
              fontWeight: "bold",
            }}
            colSpan="2"
            width="240"
          >
            <p style={{ marginRight: 21, marginBottom: 0 }}>
              {props.ag ? "Advocacy into action." : "We fight for the injured."}
            </p>
            <p
              style={{
                color: "#6680ae",
                textAlign: "right",
                fontSize: "16px",
                margin: "3px 21px 0 0",
              }}
            >
              <a
                style={{
                  color: "#6680ae",
                  textDecoration: "none",
                  fontWeight: "normal",
                }}
                href="tel:888-888-0612"
              >
                {props.ag ? "(888) 888-0612" : "(501) 777-7777"}
              </a>
            </p>
          </td>
          <td
            style={{
              padding: 0,
              borderTop: "solid 16px #c0d3e6",
              borderRight: "solid 16px #c0d3e6",
              align: "center",
              textAlign: "center",
              valign: "middle",
              width: "125px",
            }}
          >
            <a
              href="https://attorneygroup.com//?utm_medium=email_signature&amp;utm_source=email"
              style={{ margin: "8px 0", display: "inline-block" }}
            >
              <img
                style={{ margin: 0 }}
                src={icon}
                alt="Attorney Group logo"
                width="65"
                height="65"
              />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default EmailSignature;
